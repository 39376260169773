<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="评论金额" slot="price" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.price ? "￥" + row.price : "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="评论图片" slot="image" align="center">
        <template slot-scope="{ row }">
          <div class="images-box" v-if="row.images && row.images.length > 0">
            <img :src="row.images[0].smallImg" alt="" />
            <span @click="openImgBox(row)" class="text-down"
              >共{{ row.images.length }}张</span
            >
          </div>
          <div v-else>暂无图片</div>
        </template>
      </el-table-column>
      <el-table-column label="评论内容" slot="image" align="center">
        <template slot-scope="{ row }">
          <el-tooltip
            v-if="row.content"
            class="item"
            effect="dark"
            :content="row.content"
            placement="top"
          >
            <div class="Remarks">
              {{ row.content }}
            </div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="回收类型" slot="recycleType" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.recycleType">{{
            row.recycleType == "01" ? "上门回收" : "到店回收"
          }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="分配状态" slot="isDivide" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.isDivide ? "已分配" : "未分配" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="分配时间" slot="divideTime" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.divideTime ? row.divideTime : "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="添加时间" slot="createTime" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.createTime ? row.createTime : "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <!-- slot-scope="{ row }" -->
          <el-button size="mini" type="primary" :disabled="row.isDivide" round @click="problemClick(row)"
            >编辑</el-button
          >
          <el-button
            v-if="!row.isDivide"
            size="mini"
            type="danger"
            round
            @click="delClick(row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </GlobalTable>

    <!-- 编辑 -->
    <el-dialog
      title="编辑评论"
      :visible.sync="problemDialogVisible"
      :close-on-click-modal="false"
      width="700px"
      @closed="problemClosed"
    >
      <el-form
        :model="formData"
        :rules="formRulesDialog"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="回收类型" prop="recycleType">
          <el-select
            style="width: 100%"
            v-model="formData.recycleType"
            placeholder="请选择回收类型"
          >
            <el-option label="上门回收" value="01"></el-option>
            <el-option label="到店回收" value="02"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评论金额" prop="price">
          <el-input
            type="text"
            v-model.trim="formData.price"
            oninput="this.value=value.replace(/[^0-9]/g,'')"
            style="border: none"
            placeholder="请输入金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="评论内容" prop="content">
          <el-input
            type="textarea"
            maxlength="150"
            v-model="formData.content"
            :autosize="{ minRows: 7, maxRows: 7 }"
            show-word-limit
            placeholder="请输入（限150字）"
            resize="none"
          ></el-input>
        </el-form-item>
        <el-form-item label="评论图片" prop="images">
          <ElImgUploadGroup
            place-txt="文件格式为jpg/png，大小不超过500KB，建议尺寸100*100px"
            :exist-img-list="formData.images"
            :isBigImg="true"
            @handleChange="handleUploadGroupChange($event)"
          />
          <!-- :fileSize="5" -->
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="problemDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="handleConfirmDialog('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 删除 -->
    <el-dialog
      title="提示"
      :visible.sync="delDialogVisible"
      :close-on-click-modal="false"
      width="400px"
      @closed="delClosed"
    >
      <div class="mb-20">确认要删除该条数据吗？</div>
      <!-- <el-input
        v-model="formData.remark"
        placeholder="请输入谷歌动态验证码"
      ></el-input> -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="delDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="delConfirmDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 查看图片 -->
    <el-dialog
      :close-on-click-modal="false"
      :width="`${800}px`"
      title="查看照片"
      :visible="imgShow"
      @close="imgClose"
      top="5%"
    >
      <div class="img-box">
        <div class="img-show">
          <div class="imgs-num" v-if="DialogImgList.length > 0">
            {{ imgShowIndex + 1 }}/{{ DialogImgList.length }}
          </div>
          <div class="block">
            <viewer :images="DialogImgList">
              <el-carousel trigger="click" height="500px" @change="getindex">
                <el-carousel-item
                  v-for="item in DialogImgList"
                  :key="item.originalImg"
                >
                  <div
                    class="sec_img"
                    style="height: 500px; text-align: center"
                  >
                    <img :src="item.originalImg" alt="" style="height: 500px" />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </viewer>
          </div>
          <!-- <LookImg :images="imgList"/> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import ElImgUploadGroup from "../../../components/global/components/elements/upload-group-element-obj";
export default {
  components: {
    ElImgUploadGroup,
  },
  name: "Table",
  data() {
    return {
      loading: false,
      seachDataList: [],
      initData: {},
      imgShowIndex: 0,
      problemDialogVisible: false,
      delDialogVisible: false,
      imgShow: false,
      DialogImgList: [],
      formRules: {
        isEnable: [
          {
            required: true,
            trigger: "blur",
            message: "请选择问题类型",
          },
        ],
        remark: [
          {
            required: true,
            trigger: "blur",
            message: "请选择问题类型",
          },
        ],
      },
      formRulesDialog: {
        recycleType: [
          {
            required: true,
            trigger: "blur",
            message: "请选择回收类型",
          },
        ],
        price: [
          {
            required: true,
            trigger: "blur",
            message: "请输入评论金额",
          },
        ],
        content: [
          {
            required: true,
            trigger: "blur",
            message: "请输入评论内容",
          },
        ],
        images: [
          {
            required: true,
            trigger: "blur",
            message: "请上传评论图片",
          },
        ],
      },
      formData: {
        commentId: "",
        content: "",
        images: [],
        price: "",
        recycleType: "",
      },
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      tableColumns: [
        { label: "机型名称", prop: "phoneName" },
        { slotName: "price" },
        { slotName: "image" },
        { slotName: "content" },
        { slotName: "recycleType" },
        { slotName: "isDivide" },
        { slotName: "divideTime" },
        { slotName: "createTime" },
        { slotName: "operation" },
      ],
      commentId: "",
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
  },
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        phoneName: this.SeachParams.phoneName,
        isDivide: this.SeachParams.isDivide,
        recycleType: this.SeachParams.recycleType,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true;
      _api.configCommentList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.loading = false;
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    // 查看图片
    openImgBox(row) {
      console.log(row);
      this.imgShow = true;
      // this.imgList = e
      this.DialogImgList = row.images || [];
      console.log(this.DialogImgList);
    },
    getindex(e) {
      console.log(e);
      this.imgShowIndex = e;
    },
    imgClose() {
      console.log(1);
      this.imgShow = false;
    },
    // 编辑问题弹窗
    problemClick(row) {
      this.formData.images = row.images;
      // if (row.images) {
      //   row.images.forEach((ele) => {
      //     this.formData.images.push(ele.imgUrl);
      //   });
      // }
      this.formData.commentId = row.id;
      this.formData.content = row.content;
      this.formData.price = row.price;
      this.formData.recycleType = row.recycleType;
      this.problemDialogVisible = true;
    },
    // 编辑问题弹框关闭
    problemClosed() {
      this.problemDialogVisible = false;
    },
    handleUploadGroupChange(list) {
      this.formData.images = list;
    },
    handleConfirmDialog(formName) {
      console.log(this.formData);
      this.formData.images.forEach(item=>{
        item.img=item.originalImg
      })
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _api.configUpdateComment(this.formData).then((res) => {
            if (res.code === 1) {
              this.handleCurrentChange();
              this.problemDialogVisible = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    // 删除
    delClick(id) {
      this.delDialogVisible = true;
      this.commentId = id;
    },
    delClosed() {
      this.delDialogVisible = false;
    },
    delConfirmDialog() {
      _api.removeComment({ commentId: this.commentId }).then((res) => {
        if (this.seachDataList.length == 1 && this.page.pageNum > 1) {
          this.page.pageNum = this.page.pageNum - 1;
        }
        this.delDialogVisible = false;
        this.handleCurrentChange();
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mr-50 {
    margin-right: 50px;
  }
  .ml-10 {
    margin-left: 10px;
  }
  .mr-10 {
    margin-right: 10px;
  }
  .font-w {
    font-weight: bold;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-jb {
    display: flex;
    justify-content: space-between;
  }
  .flex-as {
    display: flex;
    align-items: flex-start;
  }
  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }
  .images-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .images-box > img {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
  }
  .images-box > span {
    color: #0981ff;
    cursor: pointer;
  }
  .text-down {
    color: #0981ff;
    cursor: pointer;
    text-decoration: underline;
  }
  .img-box {
    margin-bottom: 20px;
    // 图片
    .imgs-num {
      text-align: center;
      margin: 5px 0;
      font-weight: bold;
    }
    .img-tips-box {
      display: flex;
      height: 30px;
      width: max-content;
      border: 1px solid #0981ff;
      .img-tips {
        width: 80px;
        text-align: center;
        line-height: 30px;
        box-sizing: border-box;
        cursor: pointer;
      }
      .img-tips-default {
        background: #fff;
        color: #0981ff;
      }
      .img-tips-choose {
        background: #0981ff;
        color: #fff;
      }
    }
    // 列表
  }
}
</style>
