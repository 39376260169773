<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :needBtnLoading="true"
      :labelWidth="90"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <div
        style="display: flex; justify-content: flex-end; margin-bottom: 10px"
      >
        <el-button size="small" type="warning" @click="addModel"
          >分配设置</el-button
        >
        <el-button size="small" type="primary" @click="orderClick"
          >+ 导入订单</el-button
        >
      </div>
    </GlobalForm>

    <!-- 分配设置 -->
    <el-dialog
      title="分配设置"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="handleDialogClosed"
    >
      <div class="mb-10">当前未分配评论数量：{{ noDivide }}</div>
      <div class="mb-10">
        当前可分配评论数量：{{ canDivide }}
        <span>（有评论内容且照片数量大于3）</span>
      </div>
      <el-form
        :model="formData"
        :rules="formRules"
        ref="ruleForm"
        label-width="140px"
        class="demo-ruleForm"
      >
        <el-form-item label="单个商家分配数量" prop="total">
          <el-input
            type="number"
            @keyup.native="number"
            v-model.trim="formData.total"
            oninput="this.value=value.replace(/[^0-9]/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item label="分配后显示时间" prop="showTime">
          <el-date-picker
            v-model="formData.showTime"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="FromBack">取 消</el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleConfirmDialog('ruleForm')"
          >开始分配</el-button
        >
      </span>
    </el-dialog>

    <!-- 导入订单 -->
    <DialogTbale
      :show="orderDialogVisible"
      :loading="loading"
      title="导入订单"
      :columns="tableColumns"
      isSelection
      :data="dataList"
      ref="multipleTable"
      :currentPage="dialogPage.pageNum"
      :total="dialogPage.total"
      :pageSize="dialogPage.pageSize"
      :width="1200"
      @onClose="orderHandleDialogClosed"
      @handleCurrentChange="dialogCurrentChangeDialog"
      @handleSelect="handleSelect"
    >
      <div class="flex flexwarp flex-jb">
        <div class="flex mb-20">
          <label class="label">品牌</label>
          <el-select
            size="mini"
            v-model="orderFormData.brandNo"
            clearable
            filterable
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in oldBrandSelectList"
              :key="item.id"
              :label="item.name"
              :value="item.brandNo"
            ></el-option>
          </el-select>
        </div>
        <div class="flex mb-20">
          <label class="label">机型名称</label>
          <el-input
            clearable
            size="mini"
            v-model="orderFormData.phoneName"
            placeholder="请输入机型名称"
          ></el-input>
        </div>
        <div class="flex mb-20">
          <label class="label">交易时间</label>
          <el-date-picker
            type="daterange"
            size="mini"
            v-model="orderTime"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            :clearable="false"
          >
          </el-date-picker>
        </div>
        <div class="flex mb-20">
          <label class="label">订单金额</label>
          <div class="flex price_input">
            <input
              clearable
              type="text"
              v-model.trim="orderFormData.startFinalPrice"
              oninput="this.value=value.replace(/[^0-9]/g,'')"
              style="border: none"
              placeholder="起始价格"
            />~
            <input
              type="text"
              v-model.trim="orderFormData.endFinalPrice"
              oninput="this.value=value.replace(/[^0-9]/g,'')"
              style="border: none"
              placeholder="上限价格"
            />
            <svg
              v-if="
                orderFormData.startFinalPrice || orderFormData.endFinalPrice
              "
              @click="
                (orderFormData.startFinalPrice = ''),
                  (orderFormData.endFinalPrice = '')
              "
              t="1673408297273"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="5161"
              width="16"
              height="16"
              style="cursor: pointer"
            >
              <path
                d="M796 231.2c-155.5-155.5-408.5-155.5-564 0s-155.5 408.5 0 564 408.5 155.5 564 0 155.6-408.5 0-564zM765.8 765C627 903.9 401 903.9 262.2 765c-138.9-138.9-138.9-364.8 0-503.6s364.8-138.9 503.6 0 138.9 364.8 0 503.6z"
                p-id="5162"
                fill="#8a8a8a"
              ></path>
              <path
                d="M544.2 513.2L697 360.4c8.3-8.3 8.3-21.9 0-30.2s-21.9-8.3-30.2 0L514 483 361.2 330.2c-8.3-8.3-21.9-8.3-30.2 0s-8.3 21.9 0 30.2l152.8 152.8L331 665.9c-8.3 8.3-8.3 21.9 0 30.2s21.9 8.3 30.2 0L514 543.4l152.8 152.8c8.3 8.3 21.9 8.3 30.2 0s8.3-21.9 0-30.2L544.2 513.2z"
                p-id="5163"
                fill="#8a8a8a"
              ></path>
            </svg>
          </div>
        </div>

        <div style="flex: 1; margin-left: 20px" class="mb-20">
          <el-button size="mini" type="primary" @click="orderSearch">
            查询
          </el-button>
        </div>
      </div>
      <el-table-column label="订单金额" slot="finalPrice" align="center">
        <template slot-scope="{ row }">
          <!-- slot-scope="{ row }" -->
          <span>{{ row.finalPrice ? "￥" + row.finalPrice : "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单图片数量" slot="num" align="center">
        <template slot-scope="{ row }">
          <div class="images-box" v-if="row.images && row.images.length > 0">
            <!-- <img :src="row.images[0]" alt="" /> -->
            <span @click="openImgBox(row)" class="text-down">{{
              row.images.length
            }}</span>
          </div>
          <div v-else>暂无图片</div>
        </template>
      </el-table-column>
      <el-table-column
        label="评论内容"
        slot="content"
        align="center"
        width="150px"
      >
        <template slot-scope="{ row }">
          <!-- slot-scope="{ row }" -->
          <el-input
            type="textarea"
            :autosize="{ minRows: 1, maxRows: 2 }"
            placeholder="请评论内容"
            v-model="row.content"
            resize="none"
          >
          </el-input>
        </template>
      </el-table-column>
      <el-table-column label="回收类型" slot="recycleType" align="center">
        <template slot-scope="{ row }">
          <el-select v-model="row.recycleType">
            <el-option label="上门回收" value="01"></el-option>
            <el-option label="到店回收" value="02"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <!-- slot-scope="{ row }" -->
          <el-button size="mini" type="text" round @click="delRow(row.orderNo)"
            >删除并不再使用</el-button
          >
        </template>
      </el-table-column>
      <div
        slot="tableFooter"
        class="flex flex-je"
        style="padding-bottom: 20px; box-sizing: border-box"
      >
        <el-button size="small" @click="orderFromBack">取 消</el-button>
        <el-button size="small" type="primary" @click="orderCommit"
          >确定</el-button
        >
      </div>
    </DialogTbale>

    <!-- 查看图片 -->
    <el-dialog
      :close-on-click-modal="false"
      :width="`${800}px`"
      title="查看照片"
      :visible="imgShow"
      @close="imgClose"
      top="5%"
    >
      <div class="img-box">
        <div class="img-show">
          <div class="flex-jb">
            <div class="imgs-num" v-if="DialogImgList.length > 0">
              {{ imgShowIndex + 1 }}/{{ DialogImgList.length }}
            </div>
            <div>
              <el-button size="mini" type="danger" round @click="delImage"
                >删除</el-button
              >
            </div>
          </div>
          <div class="block">
            <viewer :images="DialogImgList">
              <el-carousel
                :autoplay="false"
                trigger="click"
                height="500px"
                @change="getindex"
              >
                <el-carousel-item v-for="item in DialogImgList" :key="item">
                  <div
                    class="sec_img"
                    style="height: 500px; text-align: center"
                  >
                    <img :src="item.originalImg" alt="" style="height: 500px" />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </viewer>
          </div>
          <!-- <LookImg :images="imgList"/> -->
        </div>
      </div>
    </el-dialog>

    <!-- s删除 -->
    <el-dialog
      title="删除问题"
      :visible.sync="delDialogVisible"
      :close-on-click-modal="false"
      width="400px"
      @closed="delClosed"
    >
      <div class="mb-20">确认要删除该条数据吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="delDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="delConfirmDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 删除图片 -->
    <el-dialog
      title="删除图片"
      :visible.sync="ImageDelDialogVisible"
      :close-on-click-modal="false"
      width="400px"
      @closed="ImageDelDialogVisible = false"
    >
      <div class="mb-20">确认要删除该图片吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="ImageDelDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="ImageDelDialogCommit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";
export default {
  name: "Form",
  props: {
    accountType: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      initData: null,
      dialogVisible: false,
      orderDialogVisible: false,
      delDialogVisible: false,
      ImageDelDialogVisible: false,
      loading: false,
      dialogPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      imgShow: false,
      setHideOrderDialogVisible: false,

      dataList: [],
      tableColumns: [
        { label: "订单编号", prop: "orderNo" },
        { label: "机型名称", prop: "phoneName" },
        { slotName: "finalPrice" },
        { label: "交易时间", prop: "ackTime" },
        { slotName: "num" },
        { slotName: "content" },
        { slotName: "recycleType" },
        { slotName: "operation" },
      ],
      formItemList: [
        {
          key: "phoneName",
          type: "input",
          labelName: "机型名称",
          placeholder: "输入机型名称",
        },
        {
          key: "isDivide",
          type: "select",
          lableWidth: "100px",
          labelName: "分配状态",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: true,
              label: "已分配",
            },
            {
              value: false,
              label: "未分配",
            },
          ],
        },
        {
          key: "recycleType",
          type: "select",
          lableWidth: "100px",
          labelName: "回收类型",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: "01",
              label: "上门回收",
            },
            {
              value: "02",
              label: "到店回收",
            },
          ],
        },
        {
          key: "times",
          type: "daterange",
          labelName: "分配时间",
          placeholder: "请选择分配时间",
          valueFormat: "timestamp",
        },
      ],
      formData: {
        total: null,
        showTime: "",
      },
      canDivide: 0, //当前可分配评论数量
      noDivide: 0, //当前未分配评论数量
      formRules: {
        total: [
          {
            required: true,
            trigger: "blur",
            message: "请输入分配数量",
          },
        ],
        showTime: [
          {
            required: true,
            message: "请选择分配时间",
            trigger: "change",
          },
        ],
      },
      orderFormData: {
        ackEndTime: "", //订单成交结束时间【时间戳】
        ackStartTime: "", //	订单成交开始时间【时间戳】
        endFinalPrice: "", //结束价格区间
        startFinalPrice: "", //	开始价格区间
        brandNo: "", //品牌编号
        phoneName: "", //机型名称
      },
      orderTime: null,
      imgShowIndex: 0,
      DialogImgList: [],
      currOrderNo: "",
      oldBrandSelectList: [],
      orderList: [], //导入订单至评论库
      storageArr: [], // 存取翻页前的数据
      storageArr2: [], // 存取翻页前的数据
      selectOrderList: [], //回显选择打钩
    };
  },
  created() {
    let startTime = moment()
      .day(moment().day() - 6)
      .format("YYYY-MM-DD 00:00:00"); // 当前时间往前推7天的时间
    let endTime = moment().format("YYYY-MM-DD 23:59:59");
    this.orderTime = [startTime, endTime];
    this.orderFormData.ackStartTime = new Date(startTime).getTime();
    this.orderFormData.ackEndTime = new Date(endTime).getTime();
    this.handleConfirm();
    this.getoldBrandSelectList();
  },
  watch: {},
  methods: {
    handleConfirm(data, cd) {
      let SeachParams = {};
      console.log(data);
      if (data) {
        SeachParams = {
          phoneName: data.phoneName,
          isDivide: data.isDivide,
          recycleType: data.recycleType,
          startTime: data.times ? data.times[0] : "",
          endTime: data.times ? data.times[1] : "",
          pageNum: 1,
          pageSize: 10,
          disuseLoding: () => {
            if (typeof cd === 'function') {
              cd()
            }
          },
        };
      }
      console.log("提交了form", SeachParams);
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    // 获取品牌下拉
    getoldBrandSelectList() {
      _api.oldBrandSelectList().then((res) => {
        this.oldBrandSelectList = res.data;
      });
    },
    // 分配前置查询
    getbeforeCommentDivide() {
      _api.beforeCommentDivide().then((res) => {
        console.log(res);
        this.noDivide = res.data.noDivide;
        this.canDivide = res.data.canDivide;
      });
    },
    number() {
      this.formData.total = this.formData.total.replace(".", "");
      if (this.formData.total > this.canDivide) {
        this.formData.total = "";
        this.$message.error("单个商家分配数量必须小于可分配评论数量");
      }
    },
    // 分配数量
    handleDialogClosed() {
      this.dialogVisible = false;
    },
    addModel(val) {
      this.formData.total = null;
      this.formData.showTime = "";
      this.getbeforeCommentDivide();
      this.dialogVisible = true;
    },
    FromBack() {
      this.dialogVisible = false;
    },
    // 开始分配
    handleConfirmDialog(formName) {
      if (this.formData.showTime) {
        this.formData.showTime = moment(this.formData.showTime).format(
          "YYYY-MM-DD"
        );
      }
      console.log(this.formData.showTime);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _api.configDivide(this.formData).then((res) => {
            console.log(res);
            this.dialogVisible = false;
            this.$message.success(res.msg);
          });
        } else {
          return false;
        }
      });
    },

    // 导入订单
    orderClick() {
      let startTime = moment()
        .day(moment().day() - 6)
        .format("YYYY-MM-DD 00:00:00"); // 当前时间往前推7天的时间
      let endTime = moment().format("YYYY-MM-DD 23:59:59");
      this.orderTime = [startTime, endTime];
      this.orderFormData.ackStartTime = new Date(startTime).getTime();
      this.orderFormData.ackEndTime = new Date(endTime).getTime();
      this.orderFormData.phoneName = "";
      this.orderFormData.brandNo = "";
      this.orderFormData.startFinalPrice = "";
      this.orderFormData.endFinalPrice = "";
      this.selectOrderList = [];
      this.orderDialogVisible = true;
      this.dialogCurrentChangeDialog();
    },
    orderFromBack() {
      this.orderDialogVisible = false;
    },
    orderHandleDialogClosed() {
      this.orderDialogVisible = false;
    },
    // 查询
    orderSearch() {
      if (
        this.orderFormData.endFinalPrice < this.orderFormData.startFinalPrice
      ) {
        this.$message.error("上限价格不能小于起始价格");
        this.orderFormData.endFinalPrice = "";
        return;
      }
      this.dialogCurrentChangeDialog(1);
    },
    dialogCurrentChangeDialog(val) {
      this.loading = true;
      if (this.orderTime) {
        this.orderFormData.ackStartTime = new Date(this.orderTime[0]).getTime();
        this.orderFormData.ackEndTime = new Date(this.orderTime[1]).getTime();
      }
      if (val) {
        this.dialogPage.pageNum = val;
      }
      if (this.dataList.length) {
        this.dataList.forEach((item) => {
          this.storageArr.forEach((ele) => {
            if (item.orderNo == ele.orderNo) {
              if (
                item.content != ele.content ||
                item.images.length != ele.images.length ||
                item.recycleType != ele.recycleType
              ) {
                console.log(item, "-----------");
                this.storageArr2.forEach((j, i) => {
                  if (j.orderNo == item.orderNo) {
                    this.storageArr2.splice(i, 1);
                  }
                });
                this.storageArr2.push(item);
              }
            }
          });
        });
      }
      console.log(this.storageArr2, "this.storageArr2");
      let params = {
        pageNum: this.dialogPage.pageNum,
        pageSize: this.dialogPage.pageSize,
        ackEndTime: this.orderFormData.ackEndTime, //订单成交结束时间【时间戳】
        ackStartTime: this.orderFormData.ackStartTime, //	订单成交开始时间【时间戳】
        endFinalPrice: this.orderFormData.endFinalPrice, //结束价格区间
        startFinalPrice: this.orderFormData.startFinalPrice, //	开始价格区间
        brandNo: this.orderFormData.brandNo, //品牌编号
        phoneName: this.orderFormData.phoneName, //机型名称
      };
      _api
        .getCommentOrders(params)
        .then((res) => {
          if (res.data.records.length) {
            res.data.records.forEach((item2) => {
              item2.content = "";
              this.storageArr2.forEach((ele2) => {
                if (item2.orderNo == ele2.orderNo) {
                  item2.content = ele2.content;
                  item2.recycleType = ele2.recycleType;
                  item2.images = ele2.images;
                }
              });
            });
          }
          this.storageArr = JSON.parse(JSON.stringify(res.data.records));
          this.dataList = res.data.records;
          this.dialogPage.pageNum = res.data.current;
          // this.dialogPage.pageSize = res.data.pages;
          this.dialogPage.total = res.data.total;
          let _rows = [];
          this.dataList.forEach((item) => {
            if (
              this.selectOrderList.some((ele) => ele.orderNo == item.orderNo)
            ) {
              _rows.push(item);
            }
            return item;
          });
          this.toggleSelection(_rows);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    orderCommit() {
      let arr = [];
      this.selectOrderList.forEach((item) => {
        arr.push({
          content: item.content,
          images: item.images,
          orderNo: item.orderNo,
          price: item.price,
          recycleType: item.recycleType,
        });
      });
      _api.orderImportToComment({ list: arr }).then((res) => {
        this.orderDialogVisible = false;
        this.handleConfirm();
      });
    },
    // 查看图片
    openImgBox(row) {
      console.log(row);
      this.imgShow = true;
      // this.imgList = e
      this.DialogImgList = row.images || [];
      console.log(this.DialogImgList);
    },
    getindex(e) {
      // console.log(e);
      this.imgShowIndex = e;
    },
    imgClose() {
      console.log(1);
      this.imgShow = false;
    },
    handleSelect(e) {
      console.log(e);
      this.selectOrderList = this.selectOrderList.filter((item) => {
        return !this.dataList.some((ee) => ee.orderNo == item.orderNo);
      });
      let jsonArr = e;
      let newJson = this.selectOrderList; //盛放去重后数据的新数组
      for (let item1 of jsonArr) {
        //循环json数组对象的内容
        let flag = true; //建立标记，判断数据是否重复，true为不重复
        for (let item2 of newJson) {
          //循环新数组的内容
          if (item1.orderNo == item2.orderNo) {
            //让json数组对象的内容与新数组的内容作比较，相同的话，改变标记为false
            flag = false;
          }
        }
        if (flag) {
          //判断是否重复
          newJson.push(item1); //不重复的放入新数组。  新数组的内容会继续进行上边的循环。
        }
      }
      this.selectList = newJson;

      this.selectOrderList.push(...this.selectList);
      let obj = {};
      let peon = this.selectOrderList.reduce((cur, next) => {
        obj[next.orderNo] ? "" : (obj[next.orderNo] = true && cur.push(next));
        return cur;
      }, []);
      this.selectOrderList = peon;
      console.log(this.selectOrderList);
    },
    delImage() {
      this.ImageDelDialogVisible = true;
    },
    // 确定删除图片
    ImageDelDialogCommit() {
      this.DialogImgList.splice(this.imgShowIndex, 1);
      if (this.DialogImgList == 0) {
        this.imgShow = false;
      }
      this.ImageDelDialogVisible = false;
    },
    // 回显显示效果
    toggleSelection(rows) {
      console.log(rows);
      Promise.resolve().then(() => {
        if (rows && rows.length) {
          rows.forEach((row) => {
            console.log(row);
            this.$refs.multipleTable.setSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      });
    },
    // 删除不再使用
    delRow(orderNo) {
      this.delDialogVisible = true;
      this.currOrderNo = orderNo;
    },
    delClosed() {
      this.delDialogVisible = false;
    },
    delConfirmDialog() {
      _api.setHideOrder({ orderNo: this.currOrderNo }).then((res) => {
        if (this.dataList.length == 1 && this.dialogPage.pageNum > 1) {
          this.dialogPage.pageNum = this.dialogPage.pageNum - 1;
        }
        this.dialogCurrentChangeDialog();
        this.delDialogVisible = false;
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
  .price_input {
    width: 195px;
    background-color: #ffffff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #e4ecfd;
    box-sizing: border-box;
    color: #666666;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 30px;
    > input {
      outline: 0;
      width: 70px;
      text-align: center;
    }
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mr-50 {
    margin-right: 50px;
  }
  .ml-10 {
    margin-left: 10px;
  }
  .mr-10 {
    margin-right: 10px;
  }
  .font-w {
    font-weight: bold;
  }
  .flex {
    display: flex;
    align-items: center;
  }

  .flexwarp {
    display: flex;
    flex-wrap: wrap;
  }
  .flex-jb {
    display: flex;
    justify-content: space-between;
  }
  .flex-as {
    display: flex;
    align-items: flex-start;
  }
  .flex-je {
    display: flex;
    justify-content: flex-end;
  }
  .label {
    white-space: nowrap;
    margin-right: 10px;
    width: 56px;
  }
  .images-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .images-box > span {
    color: #0981ff;
    cursor: pointer;
  }
  .text-down {
    color: #0981ff;
    cursor: pointer;
    text-decoration: underline;
  }
  .img-box {
    margin-bottom: 20px;
    // 图片
    .imgs-num {
      text-align: center;
      margin: 5px 0;
      font-weight: bold;
    }
    .img-tips-box {
      display: flex;
      height: 30px;
      width: max-content;
      border: 1px solid #0981ff;
      .img-tips {
        width: 80px;
        text-align: center;
        line-height: 30px;
        box-sizing: border-box;
        cursor: pointer;
      }
      .img-tips-default {
        background: #fff;
        color: #0981ff;
      }
      .img-tips-choose {
        background: #0981ff;
        color: #fff;
      }
    }
    // 列表
  }
  /deep/input::-webkit-inner-spin-button {
    display: none !important;
  }
}
</style>
